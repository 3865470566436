@font-face {
        font-family: 'S-CoreDream-3Light';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
        font-weight: normal;
        font-style: normal;
}
@font-face {
        font-family: 'S-CoreDream-9Black';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
        font-weight: normal;
        font-style: normal;
   }
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

html, body {
        margin: 0;
        padding: 0;
        font-size: 60%;
}

* {
        margin: 0;
        padding: 0;
}

a {
        text-decoration: none;
        color: black;
}

ul, li {
        list-style: none;
}



.header {

        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 9999;

}

.main_header {
        background-color: rgba(0,0,0,0);
}

.sub_header {
        /* background-color: rgba(0,0,0,0.7); */
        background-color: rgba(30,70,155,1);
}

.header .logo {

        padding: 0 20px;

}

.header .logo a {

        font-size: 3rem;
        font-family: 'Ubuntu', sans-serif;
        color: white;

}

.header div {
        display: flex;
        justify-content: center;
        align-items: center;
}

.header div ul {

        display: flex;
        justify-content: flex-end;
        align-items: center;

}

.header div ul li {

        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;

}

.header div ul li a {

        font-size: 2rem;
        font-family: 'Ubuntu', sans-serif;
        color: white;

}

.alltop > .tit {
        text-align: center;
        font-size: 3.5rem;
        font-family: 'Ubuntu', sans-serif;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
}
.alltop > .tit > span {
        padding-top: 70px;
        color: white;
}

@media screen and (max-width: 1000px){
        .header .nav {
                display: none!important;
        }
        
}

@media screen and (max-width: 800px){
        .alltop > .tit {
                height: 230px;
        }
}