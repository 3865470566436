.workwrap {
        margin-bottom: 100px;
}



.work {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 50px;
}

.work .one {
        float: left;
        width: 20%;
        height: 140px;
        /* background-color: #f5f5f5; */
        background-color: white;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 0;
        margin: 0.5%;
}

.work .one > div {
        width: 85%;
        margin: 5px 0;
        font-size: 1.3rem;
        font-family: 'S-CoreDream-3Light';
        color: black;
        font-weight: bolder;
}

.work .one .tit {
        font-size: 1.7rem;
}

.work .one .complete {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
}

.work .one .complete .bg {
        width: 100%;
        height: 15px;
        overflow: hidden;
        position: relative;
        border-radius: 30px;
        background-color: white;
        /* border: 1px solid darkorange; */
        border: 1px solid #5c86d0;
}

.work .one .complete .bg .bar {
        position: absolute;
        top: 0;
        left: 0;
        /* background-color: #0088ff; */
        /* background-color: darkorange; */
        background-color: #5c86d0;
        height: 100%;
        border-radius: 30px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        line-height: 0;
}
.work .one .complete .bg .bar span {
        color: white;
        font-family: 'S-CoreDream-3Light';
        font-size: 1.3rem;
}

.work.mo {
        display: none;
}





@media screen and (max-width: 1400px){
        .work .one {
                width: 30%;
        }
}

@media screen and (max-width: 1000px){
        .work .one {
                width: 45%;
        }
        .work.pc {
                display: none;
        }
        .work.mo {
                display: flex;
        }
}

@media screen and (max-width: 700px){
        .work .one {
                width: 90%;
        }
}