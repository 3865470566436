@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
        font-family: 'S-CoreDream-3Light';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
        font-weight: normal;
        font-style: normal;
}
@font-face {
        font-family: 'S-CoreDream-9Black';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
        font-weight: normal;
        font-style: normal;
   }

html, body {
        margin: 0;
        padding: 0;
        font-size: 60%;
}

* {
        margin: 0;
        padding: 0;
}

a {
        text-decoration: none;
        color: black;
}

ul, li {
        list-style: none;
}



.header {

        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 9999;

}

.main_header {
        background-color: rgba(0,0,0,0);
}

.sub_header {
        /* background-color: rgba(0,0,0,0.7); */
        background-color: rgba(30,70,155,1);
}

.header .logo {

        padding: 0 20px;

}

.header .logo a {

        font-size: 3rem;
        font-family: 'Ubuntu', sans-serif;
        color: white;

}

.header div {
        display: flex;
        justify-content: center;
        align-items: center;
}

.header div ul {

        display: flex;
        justify-content: flex-end;
        align-items: center;

}

.header div ul li {

        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;

}

.header div ul li a {

        font-size: 2rem;
        font-family: 'Ubuntu', sans-serif;
        color: white;

}

.alltop > .tit {
        text-align: center;
        font-size: 3.5rem;
        font-family: 'Ubuntu', sans-serif;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
}
.alltop > .tit > span {
        padding-top: 70px;
        color: white;
}

@media screen and (max-width: 1000px){
        .header .nav {
                display: none!important;
        }
        
}

@media screen and (max-width: 800px){
        .alltop > .tit {
                height: 230px;
        }
}
.content {

        z-index: 0;

}
.mainwrap {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;

}

.mainwrap .txt {
        margin-left: 15%;
}

.mainwrap .txt .tit {
        font-family: 'S-CoreDream-9Black';
        font-size: 5.5rem;
        line-height: 8rem;
        color: white;
}

.mainwrap .txt .desc {
        font-family: 'S-CoreDream-3Light';
        font-size: 2.5rem;
        line-height: 4rem;
        color: white;
}


@media screen and (max-width: 1000px) {
        .mainwrap {
                background-position: 60% center;
        }
        .mainwrap .txt .tit {
                font-size: 4rem;
                line-height: 6rem;
        }
        .mainwrap .txt .desc {
                font-size: 1.8rem;
                line-height: 3rem;
        }
}

@media screen and (max-width: 700px) {
        .mainwrap .txt .tit {
                font-size: 3rem;
                line-height: 4.7rem;
        }
        .mainwrap .txt .desc {
                font-size: 1.6rem;
                line-height: 2.7rem;
        }
        .mainwrap .txt {
                margin-left: 40px;
        }
}



.portfolio {

        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
}

.swiper-container {
        width: 100%;
        height: 500px;
}

.swiper-slide {
        width: 800px;
        height: 500px;
        box-sizing: border-box;
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
}

.swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
        /* background-color: white; */
        background-color: rgba(255, 255, 255, 0.5);
        padding: 30px;
        color: #000 !important;
        fill: black !important;
        stroke: black !important;
}

.swiper-button-next { right: 0; }
.swiper-button-prev { left: 0; }

.portfolio .pf_mo {
        display: none;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 100px 0;
}

.portfolio .pf_mo .one {
        width: 48%;
        margin-bottom: 15px;
}

.portfolio .pf_mo .one img {
        width: 100%;
}


@media screen and (max-width: 1000px){
        .portfolio {
                height: auto;
                justify-content: flex-start;
        }
        .swiper-container {
                display: none;
        }
        .portfolio .pf_mo {
                display: flex;
        }
}

@media screen and (max-width: 800px){
        .portfolio .pf_mo .one {
                width: 100%;
        }
}
.aboutwrap {
        margin-bottom: 100px;
}


.about {
        margin-top: 50px;
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
}
.about > div {
        width: 40%;
}

.about li {
        font-family: 'S-CoreDream-3Light';
        margin: 10px 0;
        font-size: 2rem;
        color: rgb(94, 94, 94);
}

.about .con {
        /* margin-top: 70px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.about .top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
}

.about .top > div {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 10px 0;
}

.about .top > div li {
        width: 90%;
        text-align: center;
}

.about .top > div img {
        width: 100%;
}


@media screen and (max-width: 1500px) {
        .about li {
                font-size: 1.7rem;
        }
        .about > div {
                width: 500px;
        }
}

@media screen and (max-width: 1100px) {
        .about {
                flex-direction: column-reverse;
        }
        .about .con {
                margin-top: 50px;
        }
}

@media screen and (max-width: 600px) {
        .about > div {
                width: 95%;
        }
        .about li {
                font-size: 1.3rem;
                letter-spacing: -0.1rem;
                text-align: center;
        }
}
.workwrap {
        margin-bottom: 100px;
}



.work {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 50px;
}

.work .one {
        float: left;
        width: 20%;
        height: 140px;
        /* background-color: #f5f5f5; */
        background-color: white;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 0;
        margin: 0.5%;
}

.work .one > div {
        width: 85%;
        margin: 5px 0;
        font-size: 1.3rem;
        font-family: 'S-CoreDream-3Light';
        color: black;
        font-weight: bolder;
}

.work .one .tit {
        font-size: 1.7rem;
}

.work .one .complete {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
}

.work .one .complete .bg {
        width: 100%;
        height: 15px;
        overflow: hidden;
        position: relative;
        border-radius: 30px;
        background-color: white;
        /* border: 1px solid darkorange; */
        border: 1px solid #5c86d0;
}

.work .one .complete .bg .bar {
        position: absolute;
        top: 0;
        left: 0;
        /* background-color: #0088ff; */
        /* background-color: darkorange; */
        background-color: #5c86d0;
        height: 100%;
        border-radius: 30px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        line-height: 0;
}
.work .one .complete .bg .bar span {
        color: white;
        font-family: 'S-CoreDream-3Light';
        font-size: 1.3rem;
}

.work.mo {
        display: none;
}





@media screen and (max-width: 1400px){
        .work .one {
                width: 30%;
        }
}

@media screen and (max-width: 1000px){
        .work .one {
                width: 45%;
        }
        .work.pc {
                display: none;
        }
        .work.mo {
                display: flex;
        }
}

@media screen and (max-width: 700px){
        .work .one {
                width: 90%;
        }
}
.contactwrap {
        margin-bottom: 100px;
}



.contactwrap .con {
        margin-top: 100px;
        text-align: center;
}

.contactwrap .con li {
        font-size: 2.3rem;
        font-family: 'S-CoreDream-3Light';
        margin: 25px 0;
}

.contactwrap .con2 {
        margin-top: 100px;
        text-align: center;
}

.contactwrap .con2 li,
.contactwrap .con2 li a {
        font-size: 2.3rem;
        font-family: 'S-CoreDream-3Light';
        margin: 25px 0;
}

@media screen and (max-width: 1500px) {
        .contactwrap .con li,
        .contactwrap .con2 li,
        .contactwrap .con2 li a {
                font-size: 1.7rem;
        }
}

@media screen and (max-width: 1000px) {
        .contactwrap .con,
        .contactwrap .con2 {
                margin-top: 50px;
        }
}

@media screen and (max-width: 600px) {
        .contactwrap .con li {
                font-size: 1.3rem;
                letter-spacing: -0.1rem;
                text-align: center;
        }
        .contactwrap .con2 li,
        .contactwrap .con2 li a {
                font-size: 1.3rem;
                text-align: center;
        }
}
.footer {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
}

.main_footer {
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0);
}

.sub_footer {
        /* background-color: rgba(0,0,0,0.7); */
        /* background-color: rgba(30,70,155,1); */
        background-color: #3261bd;
        height: 120px;
}

.pf_footer {
        position: fixed;
        left: 0;
        bottom: 0;
        /* background-color: rgba(0,0,0,0.7); */
        /* background-color: rgba(30,70,155,1); */
        background-color: #3261bd;
}

.footer div,
.footer div a {
        font-size: 1.5rem;
        font-family: 'S-CoreDream-3Light';
        line-height: 3rem;
        text-align: center;
        color: white;
}


@media screen and (max-width: 500px) {
        .footer div,
        .footer div a {
                font-size: 1rem;
                line-height: 2rem;
        }
}
.omberger {
        position: absolute;
        top: 25px;
        right: 20px;
        width: 30px;
        display: none!important;
}

.omberger .bt {
        width: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.omberger .bt span {
        width: 100%;
        height: 2px;
        background-color: white;
        margin: 8px 0;
}

.omberger .bt::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
}

.omberger .bt::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
}

.omberger .submenu {
        position: fixed;
        width: 80%;
        height: 100vh;
        top: 0;
        right: -100%;
        transition: all .3s;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        background-color: black;
        z-index: 99;
}

.omberger .submenu.act {
        right: 0;
        transition: all .3s;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
}

.omberger .submenu .close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.omberger .submenu .close::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
        /* transform: rotate(45deg); */
        -webkit-transform: rotate(135deg) translateX(0%);
                transform: rotate(135deg) translateX(0%);
        position: absolute;
        top: 50%;
}

.omberger .submenu .close::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
        /* transform: rotate(-45deg); */
        -webkit-transform: rotate(45deg) translateX(0%);
                transform: rotate(45deg) translateX(0%);
        position: absolute;
        top: 50%;
}

.omberger .submenu .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: auto;
        margin-top: 80px;
        padding: 0 50px;
        align-items: flex-start;
}

.omberger .submenu .list li {
        margin: 5px 0;
}

.omberger .submenu .list li a {
        color: white;
        font-size: 2rem;
}

@media screen and (max-width: 1000px){
        .omberger {
                display: block!important;
        }
}
