.contactwrap {
        margin-bottom: 100px;
}



.contactwrap .con {
        margin-top: 100px;
        text-align: center;
}

.contactwrap .con li {
        font-size: 2.3rem;
        font-family: 'S-CoreDream-3Light';
        margin: 25px 0;
}

.contactwrap .con2 {
        margin-top: 100px;
        text-align: center;
}

.contactwrap .con2 li,
.contactwrap .con2 li a {
        font-size: 2.3rem;
        font-family: 'S-CoreDream-3Light';
        margin: 25px 0;
}

@media screen and (max-width: 1500px) {
        .contactwrap .con li,
        .contactwrap .con2 li,
        .contactwrap .con2 li a {
                font-size: 1.7rem;
        }
}

@media screen and (max-width: 1000px) {
        .contactwrap .con,
        .contactwrap .con2 {
                margin-top: 50px;
        }
}

@media screen and (max-width: 600px) {
        .contactwrap .con li {
                font-size: 1.3rem;
                letter-spacing: -0.1rem;
                text-align: center;
        }
        .contactwrap .con2 li,
        .contactwrap .con2 li a {
                font-size: 1.3rem;
                text-align: center;
        }
}