.portfolio {

        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
}

.swiper-container {
        width: 100%;
        height: 500px;
}

.swiper-slide {
        width: 800px;
        height: 500px;
        box-sizing: border-box;
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
}

.swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
        /* background-color: white; */
        background-color: rgba(255, 255, 255, 0.5);
        padding: 30px;
        color: #000 !important;
        fill: black !important;
        stroke: black !important;
}

.swiper-button-next { right: 0; }
.swiper-button-prev { left: 0; }

.portfolio .pf_mo {
        display: none;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 100px 0;
}

.portfolio .pf_mo .one {
        width: 48%;
        margin-bottom: 15px;
}

.portfolio .pf_mo .one img {
        width: 100%;
}


@media screen and (max-width: 1000px){
        .portfolio {
                height: auto;
                justify-content: flex-start;
        }
        .swiper-container {
                display: none;
        }
        .portfolio .pf_mo {
                display: flex;
        }
}

@media screen and (max-width: 800px){
        .portfolio .pf_mo .one {
                width: 100%;
        }
}