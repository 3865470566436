.omberger {
        position: absolute;
        top: 25px;
        right: 20px;
        width: 30px;
        display: none!important;
}

.omberger .bt {
        width: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.omberger .bt span {
        width: 100%;
        height: 2px;
        background-color: white;
        margin: 8px 0;
}

.omberger .bt::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
}

.omberger .bt::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
}

.omberger .submenu {
        position: fixed;
        width: 80%;
        height: 100vh;
        top: 0;
        right: -100%;
        transition: all .3s;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        background-color: black;
        z-index: 99;
}

.omberger .submenu.act {
        right: 0;
        transition: all .3s;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
}

.omberger .submenu .close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.omberger .submenu .close::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
        /* transform: rotate(45deg); */
        transform: rotate(135deg) translateX(0%);
        position: absolute;
        top: 50%;
}

.omberger .submenu .close::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: white;
        /* transform: rotate(-45deg); */
        transform: rotate(45deg) translateX(0%);
        position: absolute;
        top: 50%;
}

.omberger .submenu .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: auto;
        margin-top: 80px;
        padding: 0 50px;
        align-items: flex-start;
}

.omberger .submenu .list li {
        margin: 5px 0;
}

.omberger .submenu .list li a {
        color: white;
        font-size: 2rem;
}

@media screen and (max-width: 1000px){
        .omberger {
                display: block!important;
        }
}