.aboutwrap {
        margin-bottom: 100px;
}


.about {
        margin-top: 50px;
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
}
.about > div {
        width: 40%;
}

.about li {
        font-family: 'S-CoreDream-3Light';
        margin: 10px 0;
        font-size: 2rem;
        color: rgb(94, 94, 94);
}

.about .con {
        /* margin-top: 70px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.about .top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
}

.about .top > div {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 10px 0;
}

.about .top > div li {
        width: 90%;
        text-align: center;
}

.about .top > div img {
        width: 100%;
}


@media screen and (max-width: 1500px) {
        .about li {
                font-size: 1.7rem;
        }
        .about > div {
                width: 500px;
        }
}

@media screen and (max-width: 1100px) {
        .about {
                flex-direction: column-reverse;
        }
        .about .con {
                margin-top: 50px;
        }
}

@media screen and (max-width: 600px) {
        .about > div {
                width: 95%;
        }
        .about li {
                font-size: 1.3rem;
                letter-spacing: -0.1rem;
                text-align: center;
        }
}