.mainwrap {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;

}

.mainwrap .txt {
        margin-left: 15%;
}

.mainwrap .txt .tit {
        font-family: 'S-CoreDream-9Black';
        font-size: 5.5rem;
        line-height: 8rem;
        color: white;
}

.mainwrap .txt .desc {
        font-family: 'S-CoreDream-3Light';
        font-size: 2.5rem;
        line-height: 4rem;
        color: white;
}


@media screen and (max-width: 1000px) {
        .mainwrap {
                background-position: 60% center;
        }
        .mainwrap .txt .tit {
                font-size: 4rem;
                line-height: 6rem;
        }
        .mainwrap .txt .desc {
                font-size: 1.8rem;
                line-height: 3rem;
        }
}

@media screen and (max-width: 700px) {
        .mainwrap .txt .tit {
                font-size: 3rem;
                line-height: 4.7rem;
        }
        .mainwrap .txt .desc {
                font-size: 1.6rem;
                line-height: 2.7rem;
        }
        .mainwrap .txt {
                margin-left: 40px;
        }
}


