.footer {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
}

.main_footer {
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0);
}

.sub_footer {
        /* background-color: rgba(0,0,0,0.7); */
        /* background-color: rgba(30,70,155,1); */
        background-color: #3261bd;
        height: 120px;
}

.pf_footer {
        position: fixed;
        left: 0;
        bottom: 0;
        /* background-color: rgba(0,0,0,0.7); */
        /* background-color: rgba(30,70,155,1); */
        background-color: #3261bd;
}

.footer div,
.footer div a {
        font-size: 1.5rem;
        font-family: 'S-CoreDream-3Light';
        line-height: 3rem;
        text-align: center;
        color: white;
}


@media screen and (max-width: 500px) {
        .footer div,
        .footer div a {
                font-size: 1rem;
                line-height: 2rem;
        }
}